import AWS from "aws-sdk";
import { v4 as uuidv4 } from "uuid";

const s3 = new AWS.S3({
  accessKeyId: "AKIA5SFEKY3CPFYTDLZV",
  secretAccessKey: "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",
});

const upload = (params) =>
  s3
    .upload(params)
    .promise()
    .then(async (data) => data)
    .catch((err) => {
      throw err;
    });
const uploadToS3 = async (image) => {
  let folder = `vda`;
  let fileName = `${uuidv4().replace(/\-/g, "")}.jpg`;
  let buf = new Buffer.from(
    image.replace(/^data:image\/\w+;base64,/, ""),
    "base64"
  );
  let params = {
    Bucket: `xane-user-data`,
    Key: `${folder}/${fileName}`, // File name you want to save as in S3
    Body: buf,
    ACL: "public-read",
    ContentType: `image/jpeg`,
    ContentEncoding: "base64",
  };

  try {
    const response = await upload(params);

    if (response) {
      return response;
    }
  } catch (err) {
    alert(err);
    throw err;
  }
};

export default uploadToS3;
