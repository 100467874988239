import React,{useEffect} from "react";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import {
  Button,
  Box,
  TextField,
  Icon
} from "@mui/material";
import {
  ActionRequest,
  ChatController,
} from "../index";

export function VoiceInput({
  chatController,
  actionRequest,
}: {
  chatController: ChatController;
  actionRequest: ActionRequest;
}) {
  const [value, setValue] = React.useState('');
  const {
    transcript,
    listening,
    resetTranscript,
    // browserSupportsSpeechRecognition
  } = useSpeechRecognition();

useEffect(() => {
 setValue(transcript)
}, [transcript])
  const chatCtl = chatController;

  const setResponse = React.useCallback((): void => {
    const res = { type: "voiceInput", value: value };
    chatCtl.setActionResponse(actionRequest, res);
  }, [actionRequest, chatCtl, value]);

  const handleKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>): void => {
      if (e.nativeEvent.isComposing) {
        return;
      }

      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        setResponse();
      }
    },
    [setResponse],
  );
  const startListening = () => SpeechRecognition.startListening({ continuous: true });
  const handleStartListening = () => {
    resetTranscript()
    startListening()
  }
  const handleStopListening = () => {
    SpeechRecognition.stopListening()
  }

  return (
    <Box
      sx={{
        flex: '1 1 auto',
        display: 'flex',
        '& > *': {
          flex: '1 1 auto',
          minWidth: 0,
        },
        '& > * + *': {
          ml: 1,
        },
        //   '& :last-child': {
        //     flex: '0 1 auto',
        //   },
      }}
    >
      {/* <Box
sx={{
  position: 'absolute',
  bottom: '10%',
  left: '30%',
  zIndex: 100,
  color:'gray'
}}
>{transcript}</Box> */}
      <Button
        type="button"
        //   onClick={setResponse}
        onTouchStart={handleStartListening}
        onMouseDown={handleStartListening}
        onTouchEnd={handleStopListening}
        onMouseUp={handleStopListening}
        //   disabled={!value}
        variant="contained"
        color="primary"
        startIcon={<Icon>mic
        </Icon>}
      >
      </Button>

      <TextField
        placeholder={"Type"}
        value={value}
        //   value={value?value:transcript}
        onChange={(e): void => setValue(e.target.value)}
        autoFocus
        multiline
        inputProps={{ onKeyDown: handleKeyDown }}
        variant="outlined"
        maxRows={10}
      />

      <Button
        type="button"
        onClick={setResponse}
        disabled={!value && !transcript}
        variant="contained"
        color="primary"
        startIcon={<Icon>send
        </Icon>}
      >
      </Button>
    </Box>
  );
}