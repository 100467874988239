// @ts-nocheck

import { CircularProgress } from "@material-ui/core";

import React, { useState, useEffect } from "react";
import { API_URL, sopsList } from "./apiHandler/config.js";
import ChatContainer from "./containers/ChatContainer";
import {
  readFromLocalStorage,
  writeToLocalStorage,
} from "./services/localStorage";

interface Props {}

const url = "https://xane-user-data.s3.amazonaws.com/vda/";

export const App = (props: Props) => {
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState(null);
  const fetchData = async (endpoint) => {
    setLoader(true);
    let res;
    try {
      const response = await fetch(url + endpoint);
      const convertedData = await response.json();

      await writeToLocalStorage("vda_data", convertedData);
      res = await readFromLocalStorage("vda_data");
      setData(res);
      setLoader(false);
    } catch (err) {
      alert(err);
      console.error(err);
    }
    return res;
  };
  // useEffect(() => {
  //   fetchData(API_URL + sopsList[0].value + ".json");
  // }, []);
  return true ? (
    <ChatContainer data={data} fetchData={fetchData} />
  ) : (
    <CircularProgress />
  );
};
