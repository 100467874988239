// @ts-nocheck
import {
  Box,
  LinearProgress,
  Typography,
  Fab,
  IconButton,
} from "@mui/material";

import VideoCallIcon from "@mui/icons-material/VideoCall";
import dayjs from "dayjs";
import React from "react";
import { FileDownloadRounded, Summarize } from "@mui/icons-material";

import { ChatController } from "../chat-controller";
import {
  ActionRequest,
  AudioActionRequest,
  CustomActionRequest,
  FileActionRequest,
  MultiSelectActionRequest,
  SelectActionRequest,
  TextActionRequest,
  VoiceActionRequest,
  CameraActionRequest,
} from "../chat-types";

import { MuiAudioInput } from "./MuiAudioInput";
import { MuiFileInput } from "./MuiFileInput";
import { MuiMessage } from "./MuiMessage";
import { MuiMultiSelectInput } from "./MuiMultiSelectInput";
import { MuiSelectInput } from "./MuiSelectInput";
import { MuiTextInput } from "./MuiTextInput";
import { VoiceInput } from "./VoiceInput";
import { Camera } from "./Camera";
import CSVDownloader from "../../services/CSVDownloader";

export function MuiChat({
  chatController,
}: React.PropsWithChildren<{
  chatController: ChatController;
}>): React.ReactElement {
  const chatCtl = chatController;
  const [progress, setProgress] = React.useState(0);
  const csvRef = React.useRef();
  const [messages, setMessages] = React.useState(chatCtl.getMessages());
  const [actReq, setActReq] = React.useState(chatCtl.getActionRequest());

  const msgRef = React.useRef<HTMLDivElement>(null);
  const scroll = React.useCallback((): void => {
    if (msgRef.current) {
      msgRef.current.scrollTop = msgRef.current.scrollHeight;
      // msgRef.current.scrollIntoView(true);
    }
  }, [msgRef]);

  React.useEffect(() => {
    if (messages.length > 10) {
      if (progress <= 87 && messages.length <= 30) handleProgress(progress + 1);

      if (progress <= 95 && messages.length >= 30) handleProgress(progress + 1);

      if (
        messages.length > 1 &&
        messages[messages.length - 1].content ===
          "Thank You, Happy To Serve You!"
      )
        handleProgress(100);
    }
  }, [messages]);

  React.useEffect(() => {
    console.log(actReq);
  }, [actReq]);
  React.useEffect(() => {
    console.log(messages);
  }, [messages]);

  const fetchReport = async () => {
    // if (response) {

    if (messages.length > 0) {
      csvRef.current.link.click(); //for CSV conversion
      // csvRef.current.click(); // for Excel
    }
  };

  React.useEffect(() => {
    function handleMassagesChanged(): void {
      setMessages([...chatCtl.getMessages()]);
      scroll();
    }
    function handleActionChanged(): void {
      setActReq(chatCtl.getActionRequest());
      scroll();
    }
    chatCtl.addOnMessagesChanged(handleMassagesChanged);
    chatCtl.addOnActionChanged(handleActionChanged);
  }, [chatCtl, scroll]);

  type CustomComponentType = React.FC<{
    chatController: ChatController;
    actionRequest: ActionRequest;
  }>;
  const CustomComponent = React.useMemo((): CustomComponentType => {
    if (!actReq || actReq.type !== "custom") {
      return null as unknown as CustomComponentType;
    }
    return (actReq as CustomActionRequest)
      .Component as unknown as CustomComponentType;
  }, [actReq]);

  const unknownMsg = {
    type: "text",
    content: "Unknown message.",
    self: false,
  };

  let prevDate = dayjs(0);
  let prevTime = dayjs(0);
  const handleProgress = (value) => {
    setProgress(value);
  };

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        p: 1,
        bgcolor: "background.default",
        display: "flex",
        flexDirection: "column",
        "& > *": {
          maxWidth: "100%",
        },
        "& > * + *": {
          mt: 1,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
          <Box sx={{ mr: 1, ml: 2, width: "100%" }}>
            <LinearProgress
              variant="determinate"
              color="inherit"
              value={progress}
              sx={{
                bgcolor: "white",
              }}
            />
          </Box>
          <Box sx={{ minWidth: 35 }}>
            <Typography variant="body2" color="text.secondary">{`${Math.round(
              progress
            )}%`}</Typography>
          </Box>
        </Box>
        <Box>
          {" "}
          {/* <Fab color="primary" aria-label="add" sx={{}}> */}
          <IconButton
            aria-label="call"
            size="large"
            onClick={() => {
              window.open("https://connect.platform.xane.ai/");
            }}
          >
            <VideoCallIcon color="success" />
          </IconButton>
          {/* </Fab> */}
        </Box>
        <Box>
          {" "}
          <IconButton
            aria-label="call"
            size="large"
            onClick={() => {
              fetchReport();
            }}
          >
            <Summarize />
          </IconButton>
          <CSVDownloader data={messages} fileName="Report" csvRef={csvRef} />
        </Box>
      </Box>
      <Box
        sx={{
          flex: "1 1 0%",
          overflowY: "auto",
          WebkitOverflowScrolling: "touch",
          display: "flex",
          flexDirection: "column",
          "& > *": {
            maxWidth: "100%",
          },
        }}
        ref={msgRef}
      >
        {messages.map((msg): React.ReactElement => {
          let showDate = false;
          let showTime = !!chatCtl.getOption().showDateTime;
          if (!!chatCtl.getOption().showDateTime && !msg.deletedAt) {
            const current = dayjs(
              msg.updatedAt ? msg.updatedAt : msg.createdAt
            );

            if (current.format("YYYYMMDD") !== prevDate.format("YYYYMMDD")) {
              showDate = true;
            }
            prevDate = current;

            if (current.diff(prevTime) < 60_000) {
              showTime = false;
            } else {
              prevTime = current;
            }
          }
          if (msg.type === "text" || msg.type === "jsx") {
            return (
              <MuiMessage
                key={messages.indexOf(msg)}
                id={`cu-msg-${messages.indexOf(msg) + 1}`}
                message={msg}
                showDate={showDate}
                showTime={showTime}
              />
            );
          }
          return (
            <MuiMessage
              key={messages.indexOf(msg)}
              id={`cu-msg-${messages.indexOf(msg) + 1}`}
              message={unknownMsg}
              showDate={showDate}
              showTime={showTime}
            />
          );
        })}
      </Box>
      <Box
        sx={{
          flex: "0 1 auto",
          display: "flex",
          alignContent: "flex-end",
          "& > *": {
            minWidth: 0,
          },
        }}
      >
        {actReq && actReq.type === "text" && (
          <MuiTextInput
            chatController={chatCtl}
            actionRequest={actReq as TextActionRequest}
          />
        )}
        {actReq && actReq.type === "select" && (
          <MuiSelectInput
            chatController={chatCtl}
            actionRequest={actReq as SelectActionRequest}
          />
        )}
        {actReq && actReq.type === "multi-select" && (
          <MuiMultiSelectInput
            chatController={chatCtl}
            actionRequest={actReq as MultiSelectActionRequest}
          />
        )}
        {actReq && actReq.type === "file" && (
          <MuiFileInput
            chatController={chatCtl}
            actionRequest={actReq as FileActionRequest}
          />
        )}
        {actReq && actReq.type === "audio" && (
          <MuiAudioInput
            chatController={chatCtl}
            actionRequest={actReq as AudioActionRequest}
          />
        )}
        {actReq && actReq.type === "custom" && (
          <CustomComponent
            chatController={chatCtl}
            actionRequest={actReq as CustomActionRequest}
          />
        )}
        {actReq && actReq.type === "voiceInput" && (
          <VoiceInput
            chatController={chatCtl}
            actionRequest={actReq as VoiceActionRequest}
          />
        )}
        {actReq && actReq.type === "camera" && (
          <Camera
            chatController={chatCtl}
            actionRequest={actReq as CameraActionRequest}
          />
        )}
      </Box>
    </Box>
  );
}
