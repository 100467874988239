// @ts-nocheck
import React, { useState, useEffect } from "react";
import AWS from "aws-sdk";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import {
  ActionRequest,
  AudioActionResponse,
  ChatController,
  FileActionResponse,
} from "../chatLogic/index";
import { Button, Box, Icon } from "@mui/material";

export function Detection({
  chatController,
  actionRequest,
}: {
  chatController: ChatController;
  actionRequest: ActionRequest;
}) {
  const [loading, setLoading] = useState(false);
  const [output, setOutput] = useState();
  const [image, setImage] = useState(localStorage.getItem("data"));
  const [start, setStart] = useState(false);
  useEffect(() => {
    if (image || start) {
      (async () => {
        setLoading(true);
        const s3 = new AWS.S3({
          accessKeyId: "AKIA5SFEKY3CPFYTDLZV",
          secretAccessKey: "yhsxh8CNR3e0sU7OSfqRWN6Cui8QTP+Wk9K8rKbV",
        });
        let folder = `vda   `;

        let fileName = `${uuidv4().replace(/\-/g, "")}.jpg`;
        var Location = null;
        let buf = new Buffer.from(
          image.replace(/^data:image\/\w+;base64,/, ""),
          "base64"
        );
        let params = {
          Bucket: `xane-user-data`,

          Key: `${folder}/${fileName}`, // File name you want to save as in S3
          Body: buf,
          ACL: "public-read",
          ContentType: `image/jpeg`,
          ContentEncoding: "base64",
        };
      })();
    }
  }, [image, start]);
  const chatCtl = chatController;

  const setResponse = React.useCallback(
    (response): void => {
      const res = { type: "custom", value: response };
      chatCtl.setActionResponse(actionRequest, res);
    },
    [actionRequest, chatCtl, output]
  );

  const handleClick = React.useCallback(() => {
    setStart(true);
  }, []);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Button style={{ position: "absolute", zIndex: 999 }}>Checking...</Button>
    </div>
  );
}

export default Detection;
