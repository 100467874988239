// @ts-nocheck
import React, { useEffect, useState } from "react";
import Webcam from "react-webcam";
import CircleIcon from "@mui/icons-material/Circle";
import { Button, Box, Icon } from "@mui/material";
import {
  ActionRequest,
  AudioActionResponse,
  ChatController,
  FileActionResponse,
} from "../index";
import uploadToS3 from "../../upload/uploadToS3";

const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

export function Camera({
  chatController,
  actionRequest,
}: {
  chatController: ChatController;
  actionRequest: ActionRequest;
}) {
  const webcamRef = React.useRef(null);
  const [imgSrc, setImgSrc] = React.useState(null);
  // const [imageUrl, setImageUrl] = React.useState(null);
  const [showCamera, setShowCamera] = useState(false);
  const [facingMode, setFacingMode] = React.useState(FACING_MODE_ENVIRONMENT);
  useEffect(() => {
    const upload = async () => {
      if (imgSrc) {
        const res = await uploadToS3(imgSrc);
        if (res) {
          // setImageUrl(res.Location);
          setResponse(res.Location);
        }
      }
    };
    upload();
  }, [imgSrc]);
  const chatCtl = chatController;
  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
  }, [webcamRef, setImgSrc]);

  const setResponse = React.useCallback(
    (imageUrl): void => {
      const res = { type: "text", value: imageUrl };
      chatCtl.setActionResponse(actionRequest, res);
    },
    [actionRequest, chatCtl]
  );
  const videoConstraints = {
    facingMode: { exact: facingMode },
    width: { min: 360 },
    height: { min: 640 },
    // aspectRatio: 0.6666666667
  };

  const handleClick = React.useCallback(() => {
    setFacingMode((prevState) =>
      prevState === FACING_MODE_USER
        ? FACING_MODE_ENVIRONMENT
        : FACING_MODE_USER
    );
  }, []);
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      {showCamera ? (
        <Box
          sx={{
            position: "relative",
            // border: "2px solid black",
            height: "80vh",
            width: {
              xs: "100%", // theme.breakpoints.up('xs')
              sm: "100%", // theme.breakpoints.up('sm')
              md: "100%", // theme.breakpoints.up('md')
              lg: "100%", // theme.breakpoints.up('lg')
              xl: "100%", // theme.breakpoints.up('xl')
            },
          }}
        >
          <Button
            onClick={handleClick}
            style={{ position: "absolute", zIndex: 999 }}
          >
            Switch camera
          </Button>
          <Webcam
            audio={false}
            style={{ position: "absolute", height: "100%", width: "100%" }}
            videoConstraints={videoConstraints}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
          />
          <Button
            onClick={capture}
            style={{
              zIndex: 1,
              background: "none",
              fontSize: "10rem !important",
              border: "none",
              position: "absolute",
              bottom: "50px",
              right: "50%",
              transform: "translate(50%,-50%)",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircleIcon style={{ fontSize: "10vh", color: "white" }} />
          </Button>
        </Box>
      ) : null}
      <Button
        type="button"
        onClick={() => {
          setShowCamera(!showCamera);

          // setResponse()
        }}
        variant="contained"
        color="primary"
      >
        {!showCamera ? "Open Camera" : "Close Camera"}
      </Button>
    </div>
  );
}
