// @ts-nocheck
import { CSVLink } from "react-csv";
import { multi, battery, dtc, spark, compressor, fins } from "../data/aiPoints";

const hashMap = {
  0: "multi",
  1: "battery",
  2: "dtc",
  3: "spark",
  4: "compressor",
  5: "fins",
};
const aiPointCheck = (value) => {
  if (typeof value === "object") {
    return "-";
  }
  let msg = value
    .toLowerCase()
    .replace(/[\r\n]/gm, "")
    .replaceAll(" ", "");
  let models = [multi, battery, dtc, spark, compressor, fins];
  for (let i = 0; i < models.length; i++) {
    if (models[i].includes(msg)) {
      console.log(Object.keys(models));
      return hashMap[i];
    }
  }
  return "-";
};

const typeChecker = (value) => {
  let dataType = typeof value;
  switch (dataType) {
    case "object":
      console.log(value.props.children.props.children.props.src);
      return value.props.children.props.children.props.src;
    default:
      return value;
  }
};
let report = [];
const reportMapping = (data) => {
  data.map((item, index) => {
    if (item.self === false) {
      let obj = {
        Message: typeChecker(item.content),
        AI_Model: aiPointCheck(item.content),
        Response: "-",
        createdAt: item.createdAt,
      };
      report[index] = obj;
    } else {
      report[index - 1] = {
        ...report[index - 1],
        Response: typeChecker(item.content),
      };
    }
  });

  return report.filter(function (el) {
    return el != null;
  });
};
function CSVDownloader({
  data = [{ name: "s" }],
  fileName = "inference_result",
  csvRef,
}) {
  return (
    <div className="content">
      <CSVLink
        style={{ display: "none" }}
        type="button"
        ref={csvRef}
        className="btn btn-light "
        filename={`${fileName}.csv`}
        data={reportMapping(data)}
        separator=","
        // headers={getHeaders(data)}
        target="_blank"
      />
    </div>
  );
}

export default CSVDownloader;
