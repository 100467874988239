// @ts-nocheck
import {
  Box,
  CssBaseline,
  Divider,
  Link,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";

import { ChatController, MuiChat } from "../chatLogic/index";
import { Controller } from "../controller/Controller";
import VideoCallIcon from "@mui/icons-material/VideoCall";
// } from 'chat-ui-react';
import React, { useState, useEffect } from "react";
import { CircularProgress, Fab } from "@material-ui/core";
import { readFromLocalStorage } from "../services/localStorage";
import FloatingButton from "../components/FloatingButton";
const themeColor = "#007aff";

const muiTheme = createTheme({
  palette: {
    primary: {
      main: themeColor,
    },
  },
});
const url = "https://xane-user-data.s3.amazonaws.com/vda/vda_data.json";
export function Chat(props) {
  return (
    <ThemeProvider theme={muiTheme}>
      <CssBaseline />
      <Box sx={{ height: "100%", backgroundColor: "gray" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            maxWidth: "640px",
            marginLeft: "auto",
            marginRight: "auto",
            bgcolor: themeColor, //background.default
            color: "black",
          }}
        >
          <Typography sx={{ p: 1 }} align="center">
            Virtual Diagnostic Assistance
            <Link href="https://xane.ai"></Link> <br />
            Xane AI
          </Typography>
          <FloatingButton />
          <Divider />
          <Box sx={{ flex: "1 1 0%", minHeight: 0 }}>
            <MuiChat chatController={props.chatCtl} />
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
