import VideoCallIcon from "@mui/icons-material/VideoCall";

const FloatingButton = () => {
  const handleButton = () => {
    console.log("you clicked first icon!");
  };
  return <></>;
};

export default FloatingButton;
