import { Box, Button } from "@mui/material";
import React from "react";

import { ChatController } from "../chat-controller";
import { SelectActionRequest, SelectActionResponse } from "../chat-types";

export function MuiSelectInput({
  chatController,
  actionRequest,
}: {
  chatController: ChatController;
  actionRequest: SelectActionRequest;
}): React.ReactElement {
  const chatCtl = chatController;
  let errorInputs = ["Not Good", "No"];
  let successInput = ["Yes", "Okay"];

  const setResponse = React.useCallback(
    (value: string): void => {
      const option = actionRequest.options.find((o) => o.value === value);
      if (!option) {
        throw new Error(`Unknown value: ${value}`);
      }
      const res: SelectActionResponse = {
        type: "select",
        value: option.text,
        option,
      };
      chatCtl.setActionResponse(actionRequest, res);
    },
    [actionRequest, chatCtl]
  );

  return (
    <Box
      sx={{
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        "& > *": {
          flex: "0 0 auto",
          maxWidth: "100%",
        },
        "& > * + *": {
          mt: 1,
        },
      }}
    >
      {actionRequest.options.map((o) => (
        <Button
          key={actionRequest.options.indexOf(o)}
          type="button"
          sx={{ marginTop: "4px" }}
          value={o.value}
          onClick={(e): void => setResponse(e.currentTarget.value)}
          variant="contained"
          color={(() => {
            const error = errorInputs.map((element) => {
              return element.toLowerCase();
            });
            const success = successInput.map((element) => {
              return element.toLowerCase();
            });
            if (error.includes(o.text.toLowerCase())) {
              return "error";
            }
            if (success.includes(o.text.toLowerCase())) {
              return "success";
            }
            return "primary";
          })()}
        >
          {o.text}
        </Button>
      ))}
    </Box>
  );
}
