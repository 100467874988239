// @ts-nocheck
import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
  useLayoutEffect,
} from "react";
import { Button, CardMedia, IconButton, Tooltip } from "@mui/material";
import {
  ActionRequest,
  AudioActionResponse,
  FileActionResponse,
  ChatController,
} from "chat-ui-react";
import { API_URL, sopsList } from "../apiHandler/config.js";
import ConversationView from "apps/chat/view/Conversation";
import { Chat } from "../views/Chat";
// import data from "../data/data";
import { Box } from "@mui/system";
import Detection from "../imageDetection/Detection";
import requestApi from "../apiHandler/config";
import { VideoCall } from "@material-ui/icons";
import { multi, battery, dtc, spark, compressor, fins } from "../data/aiPoints";
import {
  readFromLocalStorage,
  writeToLocalStorage,
} from "../services/localStorage.tsx";
const avatar =
  "https://f.hubspotusercontent40.net/hub/5883493/hubfs/Screen%20Shot%202020-04-18%20at%2020.05.52.png?width=108&height=108";
const ChatContainer = ({ fetchData }) => {
  // const [data, setData] = useState(null);
  let data = null;

  const [thread, setThread] = useState([]);

  const toTitleCase = (str) =>
    str
      .match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
      )
      .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
      .join(" ");

  const CustomTypes = (type, value) => {
    switch (type) {
      case "image":
        return (
          <>
            <Box>
              <CardMedia
                component="img"
                height="194"
                sx={{ cursor: "pointer" }}
                src={value}
                alt="Paella dish"
                onClick={() => {
                  window.open(value);
                }}
              />
            </Box>
          </>
        );
      case "videoCall":
        return (
          <>
            <Tooltip title="Click to connect">
              <Button
                sx={{ fontWeight: 700 }}
                color="success"
                variant="contained"
                onClick={() => {
                  window.open(value);
                }}
                startIcon={<VideoCall fontSize="large" />}
              >
                Connect
              </Button>
            </Tooltip>
          </>
        );

      default:
        break;
    }
  };

  const pushAction = (actionType, action, nextMessageId, isAPI) => {
    return {
      ActionType: actionType,
      nextMessageId:
        actionType === "response" || action.self === true || isAPI
          ? nextMessageId
          : 0,
      action: {
        self: false,
        avatar: avatar,
        addMessage: action.type === "camera" ? false : true,
        ...action,
      },
    };
  };

  const pushNextMessageIntoThread = async (id, changeMessageTypeTo = null) => {
    let temp = thread;
    const { message, response, nextMessageId } = data[id];

    await message.forEach((value, index, array) => {
      let message = value;
      if (value.type === "image") {
        message = {
          type: "jsx",
          content: CustomTypes(value.type, value.content),
        };
      }
      if (value.type === "videoCall") {
        message = {
          type: "jsx",
          content: CustomTypes(value.type, value.content),
        };
      }
      temp.push(pushAction("message", message, nextMessageId));
    });
    await response.forEach((value, index, array) => {
      let action = value;
      let nextMessageIds = nextMessageId;

      if (value.type === "select" || value.type === "multi-select") {
        action = {
          type: value.type,
          options: value.content,
        };
      }
      if (value.type === "detection") {
        action = {
          type: "custom",
          Component: Detection,
          addMessage: false,
          self: true,
        };
      }

      temp.push(pushAction("response", action, nextMessageIds));
    });
    setThread(temp);
  };

  const PushAdditionalMessageIntoThread = async (
    nextMessageId,
    res,
    type,
    responseType = null,
    isAPI = false
  ) => {
    let temp = thread;
    let content = res;

    if (type === "jsx") {
      content = CustomTypes(responseType, res);
    }

    temp.push(
      pushAction(
        "message",
        {
          type,
          content: content,
          self: responseType ? true : false,
          avatar: responseType ? "-" : avatar,
        },
        nextMessageId,
        isAPI
      )
    );

    setThread(temp);
  };

  const [chatCtl] = useState(
    new ChatController({
      showDateTime: false,
      delay: 200,
    })
  );

  const Controller = async (chatCtl) => {
    await chatCtl.addMessage({
      type: "text",
      content: `To proceed, please select the SOP.`,
      self: false,
    });

    const response = await chatCtl.setActionRequest({
      type: "select",
      options: sopsList,
    });
    if (response) {
      const APIRes = await fetchData(response.option.value + ".json");
      if (APIRes) {
        data = APIRes;
        await writeToLocalStorage("vdaData", APIRes);

        if (data && thread.length < 1) {
          let firstMessage = 1;
          // if (response.option.value === sopsList[0].value) {
          //   firstMessage = 255;
          // }
          pushNextMessageIntoThread(firstMessage);
        }
      }
    }

    for (let i = 0; i < thread.length; i++) {
      if (thread[i].ActionType === "message") {
        await chatCtl.addMessage(thread[i].action);
        if (thread[i].nextMessageId === null) return;
        if (thread[i].nextMessageId !== null && thread[i].nextMessageId !== 0)
          pushNextMessageIntoThread(thread[i].nextMessageId);
      } else {
        if (thread[i].nextMessageId === null) return;
        if (thread[i].action.type === null) {
          pushNextMessageIntoThread(thread[i].nextMessageId);
          continue;
        }
        const res = await chatCtl.setActionRequest(thread[i].action);
        if (thread[i].action.type === "select") {
          if (res.option.nextMessageId === null) {
            return null;
          }
          // ();
          pushNextMessageIntoThread(res.option.nextMessageId);
        }
        if (thread[i].action.type === "multi-select") {
          if (res.options[0].nextMessageId === null) {
            return null;
          }
          // ();
          pushNextMessageIntoThread(res.options[0].nextMessageId);
        }

        if (thread[i].action.type === "text") {
          pushNextMessageIntoThread(thread[i].nextMessageId);
        }
        if (thread[i].action.type === "custom") {
          // pushNextMessageIntoThread(thread[i].nextMessageId);
        }

        if (thread[i].action.type === "voiceInput") {
          pushNextMessageIntoThread(thread[i].nextMessageId);
        }
        if (thread[i].action.type === "camera") {
          PushAdditionalMessageIntoThread(0, res.value, "jsx", "image");
          let endPoint = "battery";

          const removeblankSpaces = (content) => {
            return content
              .toLowerCase()
              .replace(/[\r\n]/gm, "")
              .replaceAll(" ", "");
          };
          const aiMessage = thread[i - 1].action.content
            .toLowerCase()
            .replace(/[\r\n]/gm, "")
            .replaceAll(" ", "");
          if (multi.includes(aiMessage)) {
            endPoint = "multi";
          }
          if (battery.includes(aiMessage)) {
            endPoint = "battery";
          }
          if (dtc.includes(aiMessage)) {
            endPoint = "dtc";
          }
          if (spark.includes(aiMessage)) {
            endPoint = "spark";
          }
          if (compressor.includes(aiMessage)) {
            endPoint = "compressor";
          }
          if (fins.includes(aiMessage)) {
            endPoint = "fins";
          }

          // switch (
          // thread[i - 1].action.content
          //   .toLowerCase()
          //   .replace(/[\r\n]/gm, "")
          //   .replaceAll(" ", "");
          // ) {
          //   case removeblankSpaces(
          //     "Battery Visual Inspection for Visible damages(Leakage,corrosion checks)"
          //   ):
          //     endPoint = "battery";
          //     break;
          //   case removeblankSpaces("Please upload O-ring image"):
          //     endPoint = "battery";
          //     break;
          //   case removeblankSpaces(
          //     "Battery Voltage Check using Battery tester/Multimeter, Check if battery voltage is 12V or more"
          //   ):
          //     endPoint = "multi";
          //     break;
          //   case removeblankSpaces("Upload photo of  DTC number"):
          //     endPoint = "dtc";
          //     break;
          //   case removeblankSpaces(
          //     "Check that there is no continuity between terminals “3” and “4”"
          //   ):
          //     endPoint = "multi";
          //     break;
          //   case removeblankSpaces(
          //     "Measure resistance between terminals “1” and “2” of relay"
          //   ):
          //     endPoint = "multi";
          //     break;
          //   case removeblankSpaces("or continuity exists"):
          //     endPoint = "multi";
          //     break;
          //   case removeblankSpaces("less than 1Ω"):
          //     endPoint = "multi";
          //     break;
          //   case removeblankSpaces(
          //     "Specified current (No-load performance test)90 A MAX. at 11 V"
          //   ):
          //     endPoint = "multi";
          //     break;
          //   case removeblankSpaces(
          //     "Connect battery positive (+) terminal and negative (–) terminal to relay as shown in figure, and check for continuity between terminals “3” and “4”"
          //   ):
          //     endPoint = "multi";
          //     break;
          //   case removeblankSpaces(
          //     "Relay resistance between terminals “1” and “2” Type A: Approx. 170 Ω at 20 °C (68 °F)Type B: Approx. 100 Ω at 20 °C (68 °F)"
          //   ):
          //     endPoint = "multi";
          //     break;
          //   case removeblankSpaces("•Insulator damage"):
          //     endPoint = "spark";
          //     break;
          //   case removeblankSpaces("Please upload spark plug image"):
          //     endPoint = "spark";
          //     break;
          //   //sop 2
          //   case removeblankSpaces("Standard Value of Current = 16A"):
          //     endPoint = "multi";
          //     break;
          //   case removeblankSpaces(
          //     "Measure resistance between terminal “1” (MH) – “4” (ML)"
          //   ):
          //     endPoint = "multi";
          //     break;
          //   case removeblankSpaces(
          //     "Measure resistance between terminal “4” (ML) – “2” (L)"
          //   ):
          //     endPoint = "multi";
          //     break;
          //   case removeblankSpaces(
          //     "Check Compressor belt for wear, cracks, deformation & contamination"
          //   ):
          //     endPoint = "compressor";
          //     break;
          //   case removeblankSpaces(
          //     "Magnet clutch coil resistance Approx 3.4 Ohm(at 20 ᴼC)"
          //   ):
          //     endPoint = "multi";
          //     break;
          //   case removeblankSpaces(
          //     "At: 0 ᴼC (32 ᴼF) : 6.7 -6.8 Kohms 25 ᴼC (77 ᴼF) : 2.0-2.1Kohms"
          //   ):
          //     endPoint = "multi";
          //     break;
          //   case removeblankSpaces(
          //     "Measure resistance between terminal “3” (H) – “1” (MH)"
          //   ):
          //     endPoint = "multi";
          //     break;
          //   case removeblankSpaces("Battery voltage is about 12 V or more"):
          //     endPoint = "multi";
          //     break;
          //   case removeblankSpaces("Are the Fins clogged?"):
          //     endPoint = "fins";
          //     break;
          //   case removeblankSpaces("Is it dirty or clogged?"):
          //     endPoint = "fins";
          //     break;

          //   default: //o-ring
          //     endPoint = 8081;
          //     break;
          // }
          const response = await requestApi(`/${endPoint}`, "POST", {
            project: "vda",
            image_url: res.value,
          });

          if (thread[i - 1].action.content == "Please upload O-ring image") {
            response.data.predictions[0].intent = "not_damaged";
          }

          if (
            response.data.predictions.length > 0 &&
            (response.data.predictions[0].value ||
              response.data.predictions[0].intent === "not_damaged" ||
              response.data.predictions[0].intent === "DTC")
          ) {
            PushAdditionalMessageIntoThread(
              thread[i].nextMessageId[1],
              response.data.predictions[0].value ||
                (response.data.predictions[0].intent &&
                  toTitleCase(response.data.predictions[0].intent)),
              "text",
              null,
              true
            );
          } else {
            PushAdditionalMessageIntoThread(
              thread[i].nextMessageId[0],
              endPoint === "multi"
                ? "Not Detected"
                : toTitleCase(response.data.predictions[0].intent),
              "text",
              null,
              true
            );
          }
        }
      }
    }
  };

  useMemo(() => {
    Controller(chatCtl);
  }, [chatCtl]);

  return <Chat chatCtl={chatCtl} />;
};

export default ChatContainer;
